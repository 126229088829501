import type { TaskWrapperElement, TimerRootElement } from './global';
import type { QrReader } from './code-reader/qr-reader';

import whenDomReady from 'when-dom-ready';
import { GameApi } from 'urban-challenger-sdk';
import { debug } from '@gebruederheitz/debuggable';
import { EnhancedElement } from '@gebruederheitz/energize';

import { NativelyWrapper } from './natively';
import { redirectToLobbyOrGame } from './navigation/redirect-to-lobby-or-game';
import { initTeamPhoto } from './team-photo';
import {
    maybeSetLastChallenge,
    navigateToChallenge,
} from './navigation/challenge-cards';

// directly invoked tab switch snippet
import './profile-tab-switch';

import '../scss/index.scss';

class GameController {
    private readonly sdk = GameApi.get(process.env.NODE_ENV);
    private readonly debug = debug.spawn(this);
    private readonly isNative: Promise<boolean>;

    constructor() {
        let resolveIsNative;
        this.isNative = new Promise((res) => {
            resolveIsNative = res;
        });

        // Redirect users from /game and /challenge back to lobby when they're
        // not playing a game.
        redirectToLobbyOrGame(this.sdk);

        NativelyWrapper.loadNativelyScript(async () => {
            await NativelyWrapper.initNativelyDebug(this.sdk.debugBadge);
            await whenDomReady();
            resolveIsNative(NativelyWrapper.getIsNative());
            await this.initCodeReader();
        });
        maybeSetLastChallenge();
    }

    public onDomReady() {
        this.manageDebug();
        navigateToChallenge().then();
        this.initStartDemo().then();
        this.initStartGame().then();
        this.initStopGame().then();
        this.initGameTimers().then();
        this.initScoreBoard().then();
        this.initLobbies().then();
        this.initGameSummary().then();
        this.initLeaveTeamButton().then();
        this.initVoucherForm().then();
        GameController.initPaymentDropdowns().then();
        this.initPaymentDropdownZindexFix().then();
        this.initExternalLinks().then();
        this.initDeleteAccount().then();
        initTeamPhoto(this.sdk).then();
        this.initChallengeSlideshow().then();
    }

    private manageDebug(): void {
        if (this.sdk.debugBadge.debugAvailable) {
            const debugEnabledString =
                window.localStorage.getItem('uc-widgets-debug-enabled') ||
                'true';

            const debugEnabled = JSON.parse(debugEnabledString);
            debug.toggle(debugEnabled);

            this.sdk.debugBadge
                .addToggle('widgets-debug', 'Widgets', debugEnabled)
                .addEventListener('change', (e: CustomEvent) => {
                    const enabledString: 'true' | 'false' = e.detail.checked;
                    window.localStorage.setItem(
                        'uc-widgets-debug-enabled',
                        enabledString
                    );
                    const enabled = JSON.parse(enabledString);
                    debug.toggle(enabled);
                });
        }
    }

    private async initLobbies() {
        const lobbyWrapper: HTMLElement = document.querySelector(
            '[data-game-lobbies]'
        );

        if (lobbyWrapper) {
            const { Lobby } = await import('./lobby');
            // Init lobby handler, which will show and hide the appropriate
            // lobby sections based on the user's state
            new Lobby(lobbyWrapper, this.sdk);

            // Init the various widgets used across the different lobbies
            const { ClaimGame, JoinTeam } = await import('./lobby/join');

            // - Claim game form for prospective game owners
            const claimGameForm: HTMLFormElement | null =
                document.querySelector('form[data-game-claim]');
            if (claimGameForm) {
                new ClaimGame(claimGameForm, this.sdk);
            }

            // - Join team form for any normal player or team leader
            const joinTeamForm: HTMLFormElement | null = document.querySelector(
                'form[data-join-team]'
            );
            if (joinTeamForm) {
                new JoinTeam(joinTeamForm, this.sdk);
            }
        }
    }

    private async initVoucherForm() {
        // - Claim voucher form for vouchers & promo codes
        const claimVoucherForm: HTMLFormElement | null = document.querySelector(
            'form[data-game-component="claim-voucher"]'
        );
        if (claimVoucherForm) {
            const { ClaimVoucher } = await import('./claim-game');

            new ClaimVoucher(claimVoucherForm, this.sdk);
        }
    }

    private async initGameSummary() {
        const modal = document.querySelector<HTMLElement>(
            '[data-game-component="endgame"]'
        );

        if (modal) {
            const { GameSummary } = await import('./game-summary');
            new GameSummary(modal, this.sdk);
        }
    }

    private async initStartGame() {
        const startForms = document.querySelectorAll<HTMLFormElement>(
            'form[data-game-start]:not([data-game-start="team-name"])'
        );

        if (startForms.length) {
            const { LegacyStartGame } = await import('./start-game');

            startForms.forEach((form: HTMLFormElement) => {
                new LegacyStartGame(form, this.sdk);
            });
        }

        const teamNameForms = document.querySelectorAll<HTMLFormElement>(
            'form[data-game-start="team-name"]'
        );
        const startGameButtons = document.querySelectorAll<HTMLElement>(
            '[data-game-start="start-button"]'
        );

        if (teamNameForms.length || startGameButtons.length) {
            const { ChangeTeamName, StartGame } = await import('./start-game');

            teamNameForms.length &&
                teamNameForms.forEach((f) => {
                    new ChangeTeamName(f, this.sdk);
                });
            startGameButtons.length &&
                startGameButtons.forEach((b) => {
                    new StartGame(b, this.sdk);
                });
        }
    }

    private async initStopGame() {
        const stopGameButtons = document.querySelectorAll<HTMLElement>(
            '[data-game-component="stop-game"]'
        );
        if (stopGameButtons.length) {
            const { StopGame } = await import('./stop-game');
            stopGameButtons.forEach((button) => {
                new StopGame(button).connect(this.sdk);
            });
        }

        const endGameButtons = document.querySelectorAll<HTMLElement>(
            '[data-game-component="end-game"]'
        );

        if (endGameButtons.length) {
            const { EndGameButton } = await import('./end-game');
            endGameButtons.forEach((e) => {
                new EndGameButton(e).connect(this.sdk);
            });
        }
    }

    private async initGameTimers() {
        const timers: NodeListOf<TimerRootElement> = document.querySelectorAll(
            '[data-game-component="timer"]'
        );

        if (timers.length) {
            const { GameTimer } = await import('./game-timer');

            timers.forEach((element) => {
                new GameTimer(element, this.sdk);
            });
        }
    }

    private async initLeaveTeamButton() {
        const buttons = document.querySelectorAll<HTMLElement>(
            '[data-game-component="leave-team"]'
        );

        if (buttons.length) {
            const { LeaveTeam } = await import('./leave-team');

            buttons.forEach((button) => {
                new LeaveTeam(button, this.sdk);
            });
        }
    }

    private async initScoreBoard() {
        const scoreBoard: HTMLElement | null =
            document.querySelector('[data-game-score]');

        if (scoreBoard) {
            if (scoreBoard) {
                const { ScoreBoard } = await import('./score-board');

                // const board = new ScoreBoard(scoreBoard, this.sdk);
                new ScoreBoard(scoreBoard, this.sdk);
            }
        }

        /*
         * @NB:
         *   this will be mostly redundant as soon as the asynchronously-rendered
         *   modal-generating slideshow  is live. That one initializes its own
         *   status and submission widgets at runtime as required; which leaves
         *   only niche cases like the standalone challenge collection pages
         *   for this initializer.
         */
        const taskWrappers: NodeListOf<TaskWrapperElement> =
            document.querySelectorAll('[data-uc-task-id]');
        if (taskWrappers.length) {
            const { SubmissionAndStatus } = await import(
                './submission-and-status'
            );
            const isNative = await this.isNative;
            taskWrappers.forEach((wrapper) =>
                new SubmissionAndStatus(wrapper, wrapper.dataset.ucTaskId)
                    .connect(this.sdk)
                    .init(isNative)
            );
        }
    }

    private async initCodeReader(): Promise<void> {
        const qrScannerButton = document.querySelector<HTMLElement>(
            '[data-game-component="code-reader"]'
        );

        this.debug.log('scanner button', qrScannerButton);

        if (qrScannerButton) {
            const { CodeReader, NativelyQrReader, WebQrReader } = await import(
                './code-reader'
            );

            const implementation: new () => QrReader = (await this.isNative)
                ? NativelyQrReader
                : WebQrReader;

            this.debug.log(
                'launching code reader with implementation',
                implementation
            );
            new CodeReader(qrScannerButton, this.sdk, implementation);
        }
    }

    private async initExternalLinks(): Promise<void> {
        const isNative = await this.isNative;

        if (isNative) {
            const externalLinks =
                document.querySelectorAll<HTMLAnchorElement>(
                    'a[data-external]'
                );

            externalLinks.forEach((e) => {
                e.addEventListener('click', (event) => {
                    event.preventDefault();
                    const url = e.href;
                    window.natively.openExternalURL(url, true);
                });
            });
        }
    }

    private async initPaymentDropdownZindexFix() {
        const cardFooterElements = document.querySelectorAll<HTMLElement>(
            '[data-game-component="card-footer"]'
        );

        if (cardFooterElements) {
            const dropDownParentMap = new Map<HTMLElement, HTMLElement>();

            const ms = new MutationObserver((muts) => {
                for (const mutation of muts) {
                    if (mutation.type === 'attributes') {
                        if (
                            mutation.attributeName === 'aria-expanded' ||
                            mutation.attributeName === 'class'
                        ) {
                            const t = <HTMLElement>mutation.target;
                            if (
                                !t.matches('.w-dropdown-toggle') ||
                                !dropDownParentMap.has(t)
                            ) {
                                return;
                            }

                            const isOpen =
                                t.getAttribute('aria-expanded') === 'true';
                            const footerParent = dropDownParentMap.get(t);
                            const method = isOpen ? 'add' : 'remove';
                            footerParent.classList[method]('is-open');
                        }
                    }
                }
            });
            const msConfig = {
                attributes: true,
                childList: false,
                subtree: false,
            };

            cardFooterElements.forEach((footer) => {
                const ddToggle =
                    footer.querySelector<HTMLElement>('.w-dropdown-toggle');
                if (ddToggle) {
                    dropDownParentMap.set(ddToggle, footer);
                    ms.observe(ddToggle, msConfig);
                }
            });
        }
    }

    private async initStartDemo() {
        const buttons = document.querySelectorAll<HTMLElement>(
            '[data-game-component="demo-start"]'
        );
        if (buttons.length) {
            const { StartDemoGame } = await import('./start-game/demo');
            buttons.forEach((b) => new StartDemoGame(b, this.sdk));
        }
    }

    private static async initPaymentDropdowns() {
        const containers = document.querySelectorAll<HTMLElement>(
            '[data-game-component="payment"]'
        );

        if (containers.length) {
            const { pricingSelect } = await import('./pricing-select');

            await pricingSelect(containers);
        }
    }

    private async initDeleteAccount() {
        const selector = '[data-account-delete]';

        if (document.querySelector(selector)) {
            const { DeleteAccount } = await import('./delete-account');
            EnhancedElement.enhanceAll(selector, DeleteAccount, this.sdk);
        }
    }

    private async initChallengeSlideshow() {
        const selector = '[data-game-challenge="container"]';

        if (document.querySelector(selector)) {
            const { ChallengeSlideshow } = await import(
                './challenge-slideshow'
            );
            const { CategoryNavigation } = await import(
                './challenge-slideshow/category-navigation'
            );
            const { CompletedSlidesFilter } = await import(
                './challenge-slideshow/completed-slides-filter'
            );

            const isNative = await this.isNative;
            const slideshow = new ChallengeSlideshow(
                selector,
                this.sdk,
                isNative
            );
            slideshow.init().then();

            const categoryTabsSelector =
                '[data-game-component="category-tabs"]';
            if (document.querySelector(categoryTabsSelector)) {
                EnhancedElement.enhance(
                    categoryTabsSelector,
                    CategoryNavigation,
                    null,
                    slideshow
                );
            }

            const completedFilterInputSelector =
                '[data-game-component="slide-filter"]';
            if (document.querySelector(completedFilterInputSelector)) {
                await EnhancedElement.enhance(
                    completedFilterInputSelector,
                    CompletedSlidesFilter,
                    this.sdk
                ).init(slideshow);
            }
        }
    }
}

(async function () {
    const controller = new GameController();
    await whenDomReady();
    NativelyWrapper.setBodyClassFromCache();
    controller.onDomReady();
})();
