import type { SwiperSliderElement } from '../global';

import whenDomReady from 'when-dom-ready';
import { EnhancedElement } from '@gebruederheitz/energize';

const STORAGE_KEY = 'uc-last-challenge';
/**
 * If a URL query parameter "tid" or a session storage entry "uc-last-challenge"
 * exists, try to find a challenge slide on the current page (game overview) and
 * "navigate" to it by opening the app- and category-tabs the slide is in and
 * moving the slideshow to the appropriate position.
 */
export async function navigateToChallenge() {
    if (!window.location.pathname.startsWith('/game')) {
        return;
    }

    // First read from URL for direct navigation
    let taskId = new URLSearchParams(window.location.search).get('tid');

    // Otherwise check session storage
    if (!taskId) {
        const taskIdFromStorage = window.sessionStorage.getItem(STORAGE_KEY);

        if (taskIdFromStorage) {
            taskId = taskIdFromStorage;
            // Only perform this navigation once (when using history.back())
            window.sessionStorage.removeItem(STORAGE_KEY);
        }
    }

    if (taskId) {
        await whenDomReady();

        const taskSlideElement = document.querySelector<HTMLElement>(
            `[data-uc-task-id="${taskId}"]`
        );

        if (taskSlideElement) {
            const wf = (window.Webflow = window.Webflow || []);
            wf.push(() => {
                navigate(taskSlideElement);
            });
        }
    }
}

function navigate(taskSlideElement: HTMLElement) {
    const taskSlide = new EnhancedElement(taskSlideElement);
    const sliderParent = taskSlide.parent<SwiperSliderElement>('.swiper');
    const tabParent = taskSlide.parent('.challengetabs_tab');
    const appTabParent = taskSlide.parent('.apptab');

    switchTab(appTabParent);
    switchTab(tabParent);

    const targetSlideIndexString =
        taskSlideElement.dataset.swiperSlideIndex || '0';
    const targetSlideIndex = parseInt(targetSlideIndexString, 10);
    // Not "slideTo()" because we're usually in a looping slider, which
    // has duplicated and "virtual" indices
    sliderParent?.swiper?.slideToLoop(targetSlideIndex);
}

export function maybeSetLastChallenge() {
    if (window.location.pathname.startsWith('/challenge')) {
        window.sessionStorage.setItem(
            STORAGE_KEY,
            window.location.pathname.split('/').pop()
        );
    }
}

function switchTab(tabPanel: HTMLElement) {
    if (tabPanel && tabPanel.hasAttribute('aria-labelledby')) {
        const tabButton = document.querySelector<HTMLElement>(
            '#' + tabPanel.getAttribute('aria-labelledby')
        );
        tabButton?.click();
    }
}
