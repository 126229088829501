import type { GameApi } from 'urban-challenger-sdk';

import { EnhancedElement } from '@gebruederheitz/energize';

export async function initTeamPhoto(sdk: GameApi) {
    const teamPhotoDisplayElements =
        document.querySelectorAll<HTMLImageElement>(
            'img[data-team-photo="display"]'
        );

    const editContainer = document.querySelector<HTMLElement>(
        '[data-team-photo="edit"]:not(button)'
    );

    if (teamPhotoDisplayElements.length) {
        const { TeamPhotoDisplay } = await import('./display');

        teamPhotoDisplayElements.forEach((e) => {
            new TeamPhotoDisplay(e).connect(sdk);
        });
    }

    if (editContainer) {
        const { TeamPhotoEdit } = await import('./edit');

        const edit = new TeamPhotoEdit(editContainer, sdk);
        EnhancedElement.fromSelector('button[data-team-photo="edit"')?.on(
            'click',
            () => {
                edit.show();
            }
        );
    }
}
