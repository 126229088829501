import whenDomReady from 'when-dom-ready';

whenDomReady().then(() => {
    const links = document.querySelectorAll<HTMLElement>('[data-tab-link]');
    links.forEach((link) => {
        const targetId = link.dataset.tabLink;
        const target = (targetId && document.getElementById(targetId)) || null;

        if (target) {
            link.addEventListener('click', (e) => {
                e.preventDefault();

                target.click();
            });
        }
    });
});
