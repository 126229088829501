import { ApiState, GameApi, UserStatus } from 'urban-challenger-sdk';

/**
 * If the user is currently not playing a game, this will redirect them back to
 * the lobby when they're on a game overview or challenge detail page.
 */
export function redirectToLobbyOrGame(sdk: GameApi) {
    /*
     * @NB:
     *    This will only have an effect on production and should probably
     *    be configurable somehow – maybe using the URLs provided by the API
     *    server again.
     */
    // if (pathMatchesAny('/game', '/challenge', '/full-demo')) {
    if (pathMatchesAny('/game', '/challenge')) {
        const unsub = sdk.subscribe((state: ApiState) => {
            if (
                !statusMatchesAny(
                    state,
                    UserStatus.INITIAL,
                    UserStatus.PLAYING,
                    UserStatus.GAME_ENDED
                )
            ) {
                if (sdk.debugBadge.debugAvailable) {
                    sdk.toasts.make(
                        'Redirect prevented because debug is enabled'
                    );
                    unsub();
                } else {
                    /*
                     * It's up for debate whether redirecting back to the lobby page
                     * is the best way to go, or whether redirecting to the profile
                     * page would be better.
                     */
                    // @TODO: solution, maybe load URLs from API separately in
                    //        public route
                    // window.location.assign(state.urls.lobby);
                    window.location.assign('/start');
                }
            }
        });
    } else {
        const unsub = sdk.subscribe((state: ApiState) => {
            if (statusMatchesAny(state, UserStatus.PLAYING)) {
                if (sdk.debugBadge.debugAvailable) {
                    sdk.toasts.make(
                        'Redirect prevented because debug is enabled'
                    );
                    unsub();
                } else {
                    // Redirect the user back to their game
                    window.location.assign(state.urls.redirect);
                }
            }
        });
    }
}

/**
 * Returns true if the user's current UserStatus matches one of the provided
 * stati, false otherwise.
 */
function statusMatchesAny(
    state: ApiState,
    ...acceptedStati: UserStatus[]
): boolean {
    return acceptedStati.some(
        (acceptedStatus) => state.status === acceptedStatus
    );
}

/**
 * Returns true if the current URL's pathname starts with any of the provided
 * strings, false otherwise.
 */
function pathMatchesAny(...acceptedPaths: string[]): boolean {
    return acceptedPaths.some((acceptedPath) =>
        window.location.pathname.startsWith(acceptedPath)
    );
}
