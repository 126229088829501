import whenDomReady from 'when-dom-ready';
import { createElement, DebugBadge } from 'urban-challenger-sdk';

const LOCAL_STORAGE_KEY_IS_NATIVE = 'uc.isNative';
const NATIVELY_SCRIPT_URL =
    'https://cdn.jsdelivr.net/npm/natively@2.11.0/natively-frontend.min.js';

export class NativelyWrapper {
    public static loadNativelyScript(
        onLoadCallback: () => void | Promise<void>
    ) {
        const script = createElement({
            type: 'SCRIPT',
            attributes: {
                async: 'true',
                src: NATIVELY_SCRIPT_URL,
            },
        });
        script.addEventListener('load', onLoadCallback);
        document.head.appendChild(script);
    }

    // Set a class depending on the platform type ("is-native" or "is-web")
    public static setPlatformBodyClass(isNative: boolean) {
        const typeClass = isNative ? 'is-native' : 'is-web';
        document.body.classList.add(typeClass);
    }

    public static setBodyClassFromCache() {
        // Set a class depending on the platform type ("is-native" or "is-web"):
        // Initialize from cached value (if any)
        const rawValue = window.localStorage.getItem(
            LOCAL_STORAGE_KEY_IS_NATIVE
        );

        if (rawValue !== null) {
            const isNative: boolean = rawValue === 'true';
            this.setPlatformBodyClass(isNative);
        }
    }

    public static getIsNative() {
        // Set a class depending on the platform type ("is-native" or "is-web")
        const rawValue = window.localStorage.getItem(
            LOCAL_STORAGE_KEY_IS_NATIVE
        );
        let isNative: boolean;

        if (rawValue === null) {
            const browserInfo = new NativelyInfo().browserInfo();
            const isNativeApp = browserInfo.isNativeApp;
            const isIOs = browserInfo.isIOSApp;
            const isAndroid = browserInfo.isAndroidApp;
            isNative = isNativeApp || isIOs || isAndroid;
            window.localStorage.setItem(
                LOCAL_STORAGE_KEY_IS_NATIVE,
                isNative ? 'true' : 'false'
            );
            this.setPlatformBodyClass(isNative);
        } else {
            // the cache reader function has already set the body class
            isNative = rawValue === 'true';
        }

        return isNative;
    }

    public static async initNativelyDebug(
        debugBadge: DebugBadge
    ): Promise<boolean> {
        await whenDomReady();

        const isNative = this.getIsNative();

        if (debugBadge.debugAvailable && isNative) {
            let button: HTMLButtonElement | null = null;

            const debugEnabledString =
                window.localStorage.getItem('uc-natively-debug-enabled') ||
                'true';

            const debugEnabled = JSON.parse(debugEnabledString);
            window.natively?.setDebug(debugEnabled);

            // Add the toggle
            debugBadge
                .addToggle('natively-debug', 'Natively', debugEnabled)
                .addEventListener('change', (e: CustomEvent) => {
                    const enabledString: 'true' | 'false' = e.detail.checked;
                    window.localStorage.setItem(
                        'uc-natively-debug-enabled',
                        enabledString
                    );
                    const enabled = JSON.parse(enabledString);
                    window.natively?.setDebug(enabled);

                    if (enabled) {
                        debugBadge.append(button);
                    } else {
                        button?.remove();
                    }
                });

            // Add a button to open the debug logger
            button = createElement({
                type: 'BUTTON',
                content: 'Debug',
                classNames: ['w-button', 'button'],
                attributes: {
                    id: 'natively-debug-button',
                },
            });
            button.addEventListener('click', () => {
                window.natively?.openLogger();
            });

            if (debugEnabled) {
                debugBadge.append(button);
            }
        }

        return isNative;
    }
}
